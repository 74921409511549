<template>
  <v-col class="col-sm-12">
    <app-card-actions
      action-refresh
      @refresh="refreshComments"
    >
      <template slot="title">
        Comments
      </template>

      <template slot="before-actions">
        <chip
          v-if="newComments"
          :value="`${newComments} New Comment${newComments > 1 ? 's' : ''}`"
          :icon="newComments > 1 ? icons.mdiCommentTextMultiple : icons.mdiCommentText"
          color="error"
          class="mr-3"
          small
        ></chip>
      </template>

      <v-data-table
        :headers="headers"
        :items="commentsFiltered"
        item-key="id"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
        :loading="loading"
        hide-default-footer
        class="has-pagination col-sm-12 pa-2"
        loading-text="Loading... Please wait..."
        @page-count="pagination.pageCount = $event"
        @click:row="rowClickHandler"
      >
        <!-- status/is_read -->
        <template #item.is_read="{ item }">
          <checkbox
            v-model="item.is_read"
            :class="item.is_read && 'new'"
            :disabled="loading || item.is_read"
            simple
            @click.stop="commentStatusChange(item)"
          ></checkbox>
        </template>

        <!-- commentator -->
        <template #item.created_by_user_id="{ item }">
          <span :class="!item.is_read && 'new'">
            {{ commentator(item.created_by_user_id) }}
          </span>
        </template>

        <!-- origin/source -->
        <template #item.source="{ item }">
          <span :class="!item.is_read && 'new'">
            {{ item.source && $custom.toProperCase(item.source.replace(/_/g, ' ')) }}
          </span>
        </template>

        <!-- timestamp -->
        <template #item.created="{ item }">
          <span :class="!item.is_read && 'new'">
            {{ $date(item.created).format('MM/DD/YY h:mm A') }}
          </span>
        </template>
      </v-data-table>

      <!-- pagination -->
      <pagination
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.itemsPerPage"
        :page-count="pagination.pageCount"
      ></pagination>
      <v-row
        class="pagination justify-space-between mt-5 mx-4 mb-1"
      >
        <text-area
          v-if="commentAdds"
          v-model="commentBody"
          class="col-sm-10"
        >
        </text-area>
        <btn
          v-if="commentAdds"
          :text="false"
          :icon="icons.mdiSend"
          class="col-sm-1 mx-2 mt-4 pr-n12"
          :disabled="!commentBody"
          @click="commentAdd"
        >
        </btn>
      </v-row>
    </app-card-actions>

    <v-dialog
      v-model="modalState"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title
          ref="commentTitle"
          class="col-sm-12 pb-0"
        >
          <v-row>
            <v-col
              cols="auto"
              class="py-0"
            >
              Patient:&nbsp;<patient-name :patient="patient"></patient-name>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="auto"
              class="py-0"
            >
              Visit Date: {{ this.$date(comment.encounter.visit_date).format('MM/DD/YYYY') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="col-sm-12 py-0"
            >
              Facility: {{ facilityTitle() }}
            </v-col>
          </v-row>
        </v-card-title>

        <NoteSection
          :label="`From: ${commentator(comment.created_by_user_id)} on ${$date(comment.created).format('MM/DD/YY h:mm A')}`"
          class="view-comment"
        >
          <pre>{{ comment.comment }}</pre>
        </NoteSection>

        <v-card-actions>
          <btn
            label="Close"
            color="secondary"
            :icon="icons.mdiClose"
            @click="modalState = false"
          ></btn>

          <v-spacer></v-spacer>

          <btn
            label="Review Encounter"
            :icon="icons.mdiAccountMultiple"
            @click="viewEncounter(comment.encounter_id)"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import '@/components/patients/primitives'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import syncLogSetup from '@/mixins/SyncEncounters'
import {
  mdiAccountMultiple,
  mdiClose,
  mdiCommentText,
  mdiCommentTextMultiple,
  mdiSend,
} from '@mdi/js'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const initialState = {
  by: 'created',
  desc: true,
}

export default {
  components: { AppCardActions },
  mixins: [syncLogSetup],
  props: {
    // Expects: 'main', 'encounter, 'insuranceAuth'. Used in commentsFiltered()
    type: {
      type: String,
      default: 'main',
    },
    encounterId: {
      type: String,
      default: null,
    },
    patientId: {
      type: String,
      default: null,
    },
    commentAdds: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    const refreshComments = hideOverlay => {
      if (this.online) {
        this.$store.dispatch('auth/refresh')
        this.$store.dispatch('comments/loadComments')
          .then(response => {
            if (response === true) {
              this.sort = { ...initialState }
              this.$store.dispatch('notify', {
                value: 'Comments updated successfully.', color: 'success',
              })
            } else {
              this.$store.dispatch('notify', { value: `${response}!`, color: 'error' })
            }
          })
          .catch(() => {})
          .finally(() => {
            hideOverlay()
          })
      } else {
        this.$store.dispatch('notify', { value: 'Offline, can\'t download comments!', color: 'error' })
        hideOverlay()
      }
    }

    return {
      refreshComments,
      loading: false,
      sort: { ...initialState },
      modalState: false,
      userId: this.$authUser.userId(),
      comment: {
        encounter_id: null,
        created: null,
        created_by_user_id: null,
        encounter: {
          created_by_user_id: null,
        },
      },
      icons: {
        mdiAccountMultiple,
        mdiCommentTextMultiple,
        mdiCommentText,
        mdiClose,
        mdiSend,
      },
      headers: [
        {
          // Filter out comments not designated for the current provider
          value: 'encounter.created_by_user_id',
          align: 'hide',
          filter: value => value === this.userId,
        },
        {
          text: 'Read', value: 'is_read', align: 'center', sortable: false,
        },
        { text: 'Commentator', value: 'created_by_user_id' },
        {
          text: this.type === 'main' ? 'Origin' : 'Comment',
          value: this.type === 'main' ? 'source' : 'comment',
        },
        { text: 'Date & Time', value: 'created' },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: this.itemsPerPage,
      },

      // V-models
      commentBody: null,
    }
  },
  computed: {
    ...mapState('comments', { comments: 'items' }),
    ...mapFields(['online']),

    // Comments Type
    commentsFiltered() {
      switch (this.type) {
        case 'main':
          return this.comments
        case 'insuranceAuth':
          return this.comments.filter(comment => comment.source === 'insurance_auth' && comment.encounter.patient_id === this.patientId)
        case 'encounter':
          return this.comments.filter(comment => comment.source !== 'insurance_auth' && comment.encounter_id === this.encounterId)
        default:
          console.log(`Invalid prop type: ${this.type}`)

          return []
      }
    },
    newComments() {
      return this.commentsFiltered.filter(x => !x.is_read).length
    },
    newCommentTemplate() {
      return {
        encounter_id: this.encounterId,
        comment: this.commentBody,
        source: 'insurance_auth',
        patient_id: `${this.patientId}`,
        created_by_user_id: this.$authUser.userId(),
        encounter: this.$store.getters['encounters/getById'](this.encounterId),
        is_read: true,
        created: this.$custom.utcNow(),
        sync_log: this.syncLogSetup('Comments: Post'),
      }
    },
    encounter() {
      return this.$store.getters['encounters/getById'](this.comment.encounter_id)
    },
    patient() {
      return {
        ...this.$store.getters['patients/getById'](this.comment.encounter.patient_id),
        id: this.comment.encounter.patient_id,
      }
    },
    isNoteSynced() {
      return !!this.newCommentTemplate.encounter.is_synced
    },
  },
  watch: {
    modalState() {
      // Scroll to top of modal
      const modalTitle = this.$refs.commentTitle
      if (modalTitle) {
        modalTitle.scrollIntoView(true)
      }
    },
  },
  mounted() {
  },
  methods: {
    commentator(userId) {
      const user = this.$store.getters['users/getById'](userId)
      const name = `${user.first_name || ''} ${user.last_name || ''}`.trim()

      return name || userId
    },
    facilityTitle() {
      const facilityTitle = this.$store.getters['facilities/getById'](this.comment.encounter.place_of_service_id).title

      return facilityTitle || 'Facility not found.'
    },
    viewComment(item) {
      if (!this.loading) {
        // Set the comment value and open the comment modal
        const index = this.comments.findIndex(x => x.id === item.id)
        this.comment = this.comments[index]
        this.modalState = true

        // If the comment was unread, change it to read
        if (!this.comment.is_read) {
          this.commentSync(index, true)
        }
      }
    },
    commentStatusChange(item) {
      if (!item.id) {
        this.$store.dispatch('notify', { value: 'Comment read status cannot be changed for new comments', color: 'error' })
      } else if (!item.is_read) {
        this.$store.dispatch('notify', { value: 'Comments cannot be marked unread', color: 'error' })
      } else {
        console.log(item)
        const index = this.comments.findIndex(x => x.id === item.id)
        this.commentSync(index, item.is_read)
      }
    },

    // Handles updating comments is_read field
    commentSync(index, status) {
      if (this.online) {
        this.loading = true
        this.$store.dispatch('comments/syncComment', {
          id: this.comments[index].id,
          is_read: status,
          sync_log: this.syncLogSetup('Comments: Update'),
        })
          .then(response => {
            if (response === true) {
              this.comments[index].is_read = status
              this.$store.commit('comments/updateCommentSync', this.comments[index])
            } else {
              this.$store.dispatch('notify', { value: 'Comment read status update failed!', color: 'error' })
            }
          })
          .catch(() => {})
          .finally(() => {
            // Now download all comments again
            this.$store.dispatch('auth/refresh')
            this.$store.dispatch('comments/loadComments')
              .catch(() => {})
              .finally(() => {
                this.loading = false
              })
          })
      } else {
        this.$nextTick(() => {
          this.comments[index].is_read = status
          this.comments[index].updated = true
          this.$store.commit('comments/updateCommentSync', this.comments[index])

          this.$store.dispatch('notify', { value: 'Status will update on next sync. ', color: 'success' })

          this.$store.dispatch('encounters/setSynced', false)
        })
      }
    },

    // Handles post requests
    commentAdd() {
      this.$root.confirm({
        title: 'Save comment?',
        body: `${this.commentBody}`,
        cancel: 'Close',
        cancelColor: 'error',
        cancelIcon: this.icons.mdiClose,
        confirm: 'Send',
        confirmIcon: this.icons.mdiSend,
      }).then(result => {
        if (result === true) {
          if (this.online && this.isNoteSynced) {
            this.loading = true
            this.$store.dispatch('comments/postComment', this.newCommentTemplate)
              .then(response => {
                if (response.status === 200 || response.status === 'Success' || response === true) {
                  this.$store.dispatch('comments/loadComments')
                  this.$store.dispatch('notify', { value: 'Comment sent successfully. ', color: 'success' })
                  this.resetCommentBody()
                } else {
                  console.log('Error loading comments')
                }
              })
              .then(() => this.resetCommentBody())
              .catch(e => console.log(e))
          } else {
            this.$store.commit('comments/ADD_COMMENT', this.newCommentTemplate)
            this.$store.dispatch('notify', { value: 'Comment ready to be synced. ', color: 'success' })
            this.$store.dispatch('encounters/setSynced', false)
            this.resetCommentBody()
            this.loading = false
          }
        }
      })
    },
    viewEncounter() {
      // Locally, the encounter is signed and synced, but the comment says the encounter is unsigned, so unsign
      if (this.encounter.is_signed && this.encounter.is_synced && !this.comment.encounter.is_signed) {
        this.encounter.is_signed = false
        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
      }

      if (!this.encounter.created_by_user_id) {
        if (!this.$store.getters['auth/getFacilityById'](this.patient.place_of_service_id)?.id) {
          // Encounter not found, probably needs to sync
          this.$store.dispatch('notify', {
            value: `Please add "${this.facilityTitle()}" to your list of facilities to view this encounter`, color: 'error', timeout: '10000',
          })
        } else {
          // Encounter not found, probably needs to sync
          this.$store.dispatch('notify', { value: 'Encounter not found, try syncing.', color: 'error' })
        }
      } else if (this.encounter.is_signed && this.encounter.is_synced) {
        this.$store.dispatch('patients/loadPatientData', this.encounter.patient_id)
        this.$store.dispatch('encounters/loadEncounterData', this.encounter.patient_id)

        // View signed surgical note
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
          query: { id: this.encounter.id },
        })
      } else if (this.encounter.created_by_user_id !== this.userId) {
        // Don't allow a different provider to edit an encounter
        this.$store.dispatch('notify', { value: 'You can\'t edit this encounter as you\'re not the provider who created it.', color: 'error' })
      } else {
        this.$store.dispatch('patients/loadPatientData', this.encounter.patient_id)
        this.$store.dispatch('encounters/loadEncounterData', this.encounter.patient_id)

        // Edit encounter note
        this.$store.dispatch('loading', true)
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
          query: { id: this.encounter.id },
        })
      }
    },
    rowClickHandler(item) {
      if (this.type === 'main') {
        this.viewComment(item)
      }
    },

    // Resets
    resetCommentBody() {
      this.commentBody = null
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.v-data-table.comment-list .v-data-table__wrapper table tbody {
  tr {
    background-color: rgba(0, 0, 0, 0.08);
    td {
      padding: 0 0 !important;
      > * {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0 6px;
        padding-top: 13px;
      }
      .new {
        font-weight: 600;
        background-color: map-get($shades, 'white');
      }
    }
    &.v-data-table__empty-wrapper {
      background-color: map-get($shades, 'white');
    }
  }
}
.view-comment {
  .v-card__title {
    margin: 12px 0;
    text-align: left;
  }
  >.row {
    width: 100%;
    background-color: map-get($shades, 'white');
    border: thin solid $component-border-color;
    border-radius: 4px !important;
    padding: 20px !important;
    margin: 0 0 20px 0;
    pre {
      padding: 0 !important;
      font-family: "Inter", sans-serif;
      font-size: 18px !important;
      color: var(--v-secondary-darken4);
    }
  }
}
</style>
